import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Link,
} from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import { useGetTicketInfoById } from "../api/queries";
import { LinkIcon } from "@chakra-ui/icons";
import { TicketNotFound } from "./ticket-not-found";
import { Loading } from "../loading";

interface TicketDetailParams {
  ticketId: string;
  [key: string]: string;
}

export const TicketDetail = () => {
  const { ticketId } = useParams<TicketDetailParams>();
  const { data, isError, isLoadingError, isLoading } = useGetTicketInfoById(
    ticketId!
  );

  if (isLoading) {
    return <Loading />;
  }

  if (isError || isLoadingError) {
    return <TicketNotFound />;
  }

  return (
    <Box>
      <Box marginBottom={4}>
        <Alert
          status="success"
          variant="subtle"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          borderRadius={6}
        >
          <AlertIcon boxSize={12} my={4} />
          <AlertTitle my={1}>Dein Download ist bereit!</AlertTitle>
          <AlertDescription>
            Klicke auf den unten stehenden Link, um deine Angelkarte
            herunterzuladen.
          </AlertDescription>
          <Box p={3} >
            <Link href={data?.downloadLink} target="_blank" isExternal>
              Ticket als PDF <LinkIcon mx="2px" mb={1} />
            </Link>
          </Box>
        </Alert>
      </Box>
      <Box>
        <Alert
          status="warning"
          variant="subtle"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          borderRadius={6}
        >
          <AlertIcon boxSize={12} my={4} />
          <AlertTitle my={1}>Bitte die Fangliste ausfüllen!</AlertTitle>
          <AlertDescription>
            Klicke auf den unten stehenden Link oder scanne den Code auf dem
            Ticket, um zur Fangliste zu gelangen.
          </AlertDescription>
          <Box p={3}>
            <Link href={`${ticketId}/fangliste`} target="_blank">
              Fangliste <LinkIcon mx="2px" mb={1} />
            </Link>
          </Box>
        </Alert>
      </Box>
    </Box>
  );
};
