import { Box, Text } from "@chakra-ui/react";

export const Footer = () => {
  return (
    <Box h="100%" display="flex" alignItems="center" justifyContent="center">
      <Text textAlign="center">
        © angelkarte.net {new Date().getFullYear()}
      </Text>
    </Box>
  );
};
