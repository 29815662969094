export function formatDate(date: Date) {
    return date?.toLocaleDateString("de-DE", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
    });
}
export function formatDateTime(date: Date) {
    return date.toLocaleTimeString("de-DE", {
        hour: '2-digit',
        minute: '2-digit',
    });
}