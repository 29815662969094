import { Input, Select, SimpleGrid } from "@chakra-ui/react";
import { useField, useFormikContext } from "formik";
import { FormControl } from "formik-chakra-ui";
import ReactDatePicker from "react-datepicker";
import { formatDate } from "../../util";

const hourOptions = Array.from({ length: 24 }, (_, idx) => ({
  value: idx,
  label: `${idx.toString().padStart(2, '0')}:00 Uhr`,
}));

interface DateTimePickerControlProps {
  name: string;
  label: string;
  isRequired?: boolean;
  onFieldChange(newValue: Date): void;
}

export const DateTimePickerControl = (props: DateTimePickerControlProps) => {
  const [field] = useField<Date>(props);
  const { setFieldValue } = useFormikContext();
  const currentHour = field.value?.getHours();

  return (
    <FormControl name={props.name} label={props.label} isRequired={props.isRequired}>
      <SimpleGrid columns={2} columnGap={2}>
        <ReactDatePicker
          customInput={<Input readOnly value={formatDate(field.value)} />}
          adjustDateOnChange
          minDate={new Date()}
          onChange={(selectedDate) => {
            const newFieldValue = new Date(selectedDate as Date);
            newFieldValue.setHours(currentHour, 0, 0);
            setFieldValue(props.name, newFieldValue);
            props.onFieldChange(newFieldValue);
          }}
          value={formatDate(field.value)}
        />
        <Select value={currentHour} onChange={(e) => {
          const newFieldValue = new Date(field.value);
          newFieldValue.setHours(Number(e.target.value), 0, 0);
          setFieldValue(props.name, newFieldValue);
          props.onFieldChange(newFieldValue);
        }}>
          {hourOptions.map(option => (
            <option key={option.value} value={option.value}>{option.label}</option>
          ))}
        </Select>
      </SimpleGrid>
    </FormControl>
  );
};
