import { Outlet } from "react-router-dom"
import { Route, Routes } from "react-router-dom"
import { CatchLists } from "../catch-lists/catch-lists"
import { TicketDetail } from "./ticket-detail"
import { TicketPurchase } from "./ticket-purchase"
import { TicketValidation } from "./ticket-validation"

export const Tickets = () => {
    return (
        <>
            <Routes>
                <Route path="/" element={<TicketPurchase />} />
                <Route path=":ticketId" element={<TicketDetail />} />
                <Route path=":ticketId/gueltigkeit" element={<TicketValidation />} />
                <Route path=":ticketId/fangliste" element={<CatchLists />} />
            </Routes>

            <Outlet />
        </>
    )
}