import { ExternalLinkIcon } from "@chakra-ui/icons";
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Link,
} from "@chakra-ui/react";

export const TicketNotFound = () => {
  return (
    <Box>
      <Alert
        status="error"
        variant="subtle"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        borderRadius={6}
      >
        <AlertIcon boxSize={12} my={4} />
        <AlertTitle my={1}>Tageskarte nicht gefunden!</AlertTitle>
        <AlertDescription>
          Bitte überprüfe noch einmal den Link, dem du gefolgt bist.
        </AlertDescription>
        <Box p={3} mt={3}>
          <Link href={`/`}>
            Zurück zur Startseite <ExternalLinkIcon mx="2px" />
          </Link>
        </Box>
      </Alert>
    </Box>
  );
};
