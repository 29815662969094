import "@fontsource/open-sans/700.css";
import "@fontsource/source-sans-3";
import "./index.css";

import { Box, ChakraProvider, Divider } from "@chakra-ui/react";
import "react-datepicker/dist/react-datepicker.css";
import { BrowserRouter as Router } from "react-router-dom";
import { Header } from "./header";
import { AppRoutes } from "./app-routes";
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import { paypalConfig } from "./paypal-config";
import { QueryClientProvider } from "react-query";
import { queryClient } from "./query-client";
import { appTheme } from "./theme";
import { Footer } from "./footer";

const headerHeight = 72;
const footerHeight = 56;
const contentHeight = `calc(100vh - ${headerHeight}px - ${footerHeight}px)`;

function App() {
  return (
    <PayPalScriptProvider options={paypalConfig} deferLoading>
      <QueryClientProvider client={queryClient}>
        <ChakraProvider theme={appTheme}>
          <Router>
            <Box>
              <Box height={`${headerHeight}px`}>
                <Header />
                <Divider />
              </Box>
              <Box p={4} height={contentHeight} overflow="auto" display="flex" justifyContent="center">
                <Box maxWidth="container.xl" w="100%" >
                  <AppRoutes />
                </Box>
              </Box>
              <Box w="100%" height={`${footerHeight}px`} background="white">
                <Divider />
                <Footer />
              </Box>
            </Box>
          </Router>
        </ChakraProvider>
      </QueryClientProvider>
    </PayPalScriptProvider>
  );
}

export default App;
