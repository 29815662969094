import { Box, Button, Heading, Input, Text } from "@chakra-ui/react";
import faker from "@faker-js/faker";
import { useNavigate } from "react-router-dom";
import {
  FormControl,
  FormLabel,
} from '@chakra-ui/react'

export const Home = () => {
  const navigate = useNavigate();
  return (
    <Box display="flex" alignItems="center" flexDirection="column" p={4}>
      <Box maxWidth="container.md">
        <Heading textAlign="center" mb={8} size="lg">Angelkarten online kaufen</Heading>
        <Box w="100%" maxWidth="">
          <Text>{faker.lorem.paragraph(2)}</Text>
        </Box>

        <Box my={8}>
          <FormControl>
            <FormLabel htmlFor='tenant'>Verein oder Gewässer</FormLabel>
            <Input id='tenant' type='text' isReadOnly value="SFV Schoppenteich e.V. (Saale)" />
          </FormControl>
        </Box>

        <Box display="flex" justifyContent="center">
          <Button
            w="100%"
            colorScheme="green"
            onClick={() => {
              navigate("/angelkarten");
            }}
          >
            Jetzt Tageskarte kaufen
          </Button>
        </Box>
      </Box>
    </Box>
  );
};
