import { Box, SimpleGrid } from "@chakra-ui/react";
import dayjs from "dayjs";
import { useFormikContext } from "formik";
import { InputControl } from "formik-chakra-ui";
import { DateTimePickerControl } from "../components/form-controls/date-time-picker-control";

interface TicketOrderFormProps {}
export const TicketOrderForm = (_props: TicketOrderFormProps) => {
  const formikCtx = useFormikContext();
  return (
    <Box>
      <SimpleGrid spacing={4} columns={{ sm: 1, md: 2 }}>
        <DateTimePickerControl name="validFrom" label="Gültigkeit von" isRequired onFieldChange={(newValidFrom) => {
          formikCtx.setFieldValue('validTo', dayjs(newValidFrom).add(1, 'day').toDate(), false);
        }} />
        <DateTimePickerControl name="validTo" label="Gültigkeit bis" onFieldChange={(newValidTo) => {
          formikCtx.setFieldValue('validFrom', dayjs(newValidTo).subtract(1, 'day').toDate(), false);
        }} />
        <InputControl
          name="firstName"
          label="Vorname"
          isRequired
          mb={2}
          inputProps={{
            autoComplete: "given-name",
            placeholder: "Vorname",
          }}
        />
        
        <InputControl
          name="lastName"
          label="Nachname"
          isRequired
          mb={2}
          inputProps={{
            autoComplete: "family-name",
            placeholder: "Nachname",
          }}
        />
        <InputControl
          name="email"
          label="E-Mail-Adresse"
          mb={2}
          isRequired
          inputProps={{
            autoComplete: "email",
            type: "email",
            placeholder: "mail@example.com",
          }}
        />
        <InputControl
          name="confirmEmail"
          label="E-Mail-Adresse bestätigen"
          mb={2}
          isRequired
          inputProps={{
            autoComplete: "off",
            type: "email",
            placeholder: "mail@example.com",
          }}
        />
        <InputControl
          name="licenseNumber"
          label="Erlaubnisschein-Nummer"
          isRequired
          mb={2}
          inputProps={{
            autoComplete: "off",
            placeholder: "Erlaubnisschein-Nummer",
          }}
        />
        <InputControl
          name="street"
          label="Straße"
          isRequired
          mb={2}
          inputProps={{
            autoComplete: "street-address",
            placeholder: "Straße",
          }}
        />
        <InputControl
          name="houseNumber"
          label="Hausnummer"
          isRequired
          mb={2}
          inputProps={{
            placeholder: "Hausnummer",
          }}
        />
        <InputControl
          name="zipcode"
          label="Postleitzahl"
          isRequired
          mb={2}
          inputProps={{
            autoComplete: "postal-code",
            placeholder: "Postleitzahl",
          }}
        />
        <InputControl
          name="city"
          label="Stadt"
          isRequired
          mb={2}
          inputProps={{
            autoComplete: "address-level2",
            placeholder: "Stadt",
          }}
        />
      </SimpleGrid>
    </Box>
  );
};
