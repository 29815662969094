import { HamburgerIcon, InfoIcon, LockIcon } from "@chakra-ui/icons";
import {
  Box,
  Heading,
  IconButton,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import logo from "./logo.png";

const MobileMenu = () => {
  return (
    <Menu closeOnBlur>
      <MenuButton
        as={IconButton}
        aria-label="Options"
        icon={<HamburgerIcon />}
        variant="outline"
      />
      <MenuList>
        <Link to="/impressum">
          <MenuItem icon={<InfoIcon boxSize={3} />} autoFocus={false}>
            Impressum
          </MenuItem>
        </Link>
        <Link to="/datenschutz">
          <MenuItem icon={<LockIcon boxSize={3} />} autoFocus={false}>
            Datenschutz
          </MenuItem>
        </Link>
      </MenuList>
    </Menu>
  );
};

export const Header = () => {
  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      p={4}
    >
      <Link to="/">
        <Box display="flex">
          <Image src={logo} h={30} mr={4}></Image>
          <Heading as="h1" size="md" lineHeight={1.5}>
            angelkarte.net
          </Heading>
        </Box>
      </Link>
      <MobileMenu />
    </Box>
  );
};
